import NotFoundPage from '@containers/NotFound';
import Head from 'next/head';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const NotFound = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace('/');
  }, []);

  return null;

  // return (
  //   <>
  //     <Head>
  //       <title>Page Not Found - The Cumin Club</title>
  //     </Head>
  //     <NotFoundPage />
  //   </>
  // );
};

export default NotFound;
